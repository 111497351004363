export const environment = {
  name: 'uat',
  production: true,
  sharedSsoApi: 'https://shared-sso-api-uat.cubigo.com/api/v1/',
  azureApiManagementUrl: 'https://cubigo-uat.azure-api.net/web-ds/v1/',
  ssoUrl: 'https://uat-sso.cubigo.com/',
  appInsights: {
    instrumentationKey: 'e3b38655-496e-478d-bf58-9d4ca592070c',
    instrumentationKeyEu: '3ef16163-0aad-4eac-83aa-0dd771d0addc',
    instrumentationKeyCa: 'e3b38655-496e-478d-bf58-9d4ca592070c'
  },
  identityServer: {
    clientId: 'WebDigitalSignageUat',
    clientSecret: 'b29029ab-7c6a-43ae-a98f-a6830727b482',
    scope: 'digital_signage offline_access'
  },
  pubnub: {
    subKey: 'sub-c-deacb104-74c2-11ea-b179-9eded212fb8b',
    pubKey: 'pub-c-92dd3d14-1c4d-4f83-844a-684d70664d55'
  },
  ocpApimSubscriptionKey: '807d67bc416f4129b96ee66dfcf704db',
  ocpApimSubscriptionKeyEu: '9e21b52e4beb4db7a1d7ec529a452700',
  ocpApimSubscriptionKeyCa: '', // Not supported
  refreshDuration: {
    mainContent: 60000 * 10,
    secondaryContent: 60000 * 35,
    config: 60000 * 60,
    prioNews: 60000 * 10,
    randomDelay: 60000 * 5
  },
  dsCdnUrl: 'https://d3ifvr28d907b9.cloudfront.net/ds-uat/'
};
